<template>
  <Layout>
    <div class="md-Logistics">
      <!-- 轮播 -->
      <div class="log-layout-1">
        <div class="base-width">
          <Banner :list="bannerList" height="4.92rem" v-ani.fade-up />
        </div>
      </div>
      <div class="log-layout-2">
        <div class="base-width" v-ani.fade-up>
          <div class="texts">
            <div class="ti">
              <div class="c-title-2 s22">云仓标准体系</div>
              <div class="d2 s16">
                易筑平台通过整合专业仓储物流公司,与平台共建数字化仓储物流中心,为建筑工程产业链上下游提供共享仓储物流服务。
                <br />数字化仓储物流通过运用大数据和人工智能搭建智能化的仓储物流管理系统,可以显著的提高仓储物中心的生产力。
                <br />随着易筑平台对建筑工程供应链各环节的有效整合,数字化仓储物流将有助于打造产业价值链重构、数据融合、精准连接的智慧供应链,实现对整个产业链的智能管理。
              </div>
            </div>

            <div class="ti">
              <div class="c-title-2 s22">云仓分布</div>
              <div class="d2">
                <p>云仓现已分布地区包括：广东省、湖南省、江西省、其中城市包括：广州、佛山、东莞、阳江、赣州、长沙、重庆等15城市地区</p>
                <p>
                  <br />
                </p>
                <p style="text-align:center">
                  <img src="@/assets/images/log-lay2_1.jpg" alt />
                </p>
              </div>
            </div>

            <ServiceProcess :info="info" v-ani.fade-up />
          </div>
        </div>
      </div>
      <div class="log-layout-3">
        <div class="base-width">
          <div class="c-title-2 s22" v-ani.fade-up>物流合作单位</div>
          <div class="list">
            <div class="li" v-for="(item, index) in list3" :key="index" v-ani.fade-up>
              <div class="imgs">
                <div class="img">
                  <img :src="item.img" alt class="c-img" />
                </div>
              </div>
              <div class="texts">
                <div class="d1 s18 font-medium">{{ item.tit }}</div>
                <div class="d2 s16" v-html="item.tes"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<script>
import { IndexBannerApi } from "@/request/api/user";
export default {
  created() {
    // 轮播图
    IndexBannerApi({ from: 424 }).then(({ status, data }) => {
      if (status == 200) {
        this.bannerList = data;
      }
    })
  },
  data() {
    return {
      // 轮播图列表
      bannerList: [],
      list3: [
        {
          img: require("@/assets/images/log-lay3_1.jpg"),
          tit: "德邦物流",
          tes: "德邦快递以大件快递为核心业务，主要业务涉及快运、整车、仓储与供应链 ，是中国企业500强之一。多年来，公司坚持“以客户为中心，以进取者为本，以团队创高效，坚持自我反思”的核心价值观，致力于为用户提供高效、快捷的货运解决方案。  <br>   德邦快递始终紧随客户需求而持续创新，坚持自营门店与事业合伙人相结合的网络拓展模式，搭建优选线路，以优化运力成本，为客户提供快速高效、便捷及时、安全可靠的服务。",
        },
        {
          img: require("@/assets/images/log-lay3_2.jpg"),
          tit: "货拉拉",
          tes: "货拉拉于2013年创立，成长于粤港澳大湾区。是一家从事同城/跨城货运、企业版物流服务、搬家、零担、汽车租售及车后市场服务的互联网物流商城。 <br> 货拉拉通过共享模式整合社会运力资源，完成海量运力储备，依托移动互联、大数据和人工智能技术，搭建“方便、科技、可靠”的货运平台，实现多种车型的即时智能调度，为个人、商户及企业提供高效的物流解决方案。",
        },
        {
          img: require("@/assets/images/log-lay3_3.jpg"),
          tit: "自有物流",
          tes: "聚焦建筑行业物资配送痛点，整合优质物流承运商打造在线发货平台。 <br>作为一家自营的物流公司，我们致力于为客户提供高效、安全、可靠的物流解决方案。从支付派车、到场装货、拍照上传、运输轨迹、到货拍照签收全程节点的监控和追溯。 <br> 我们致力于提供高效、稳定的物流服务，确保货物的及时到达，也同时为用户提供更经济的物流解决方案，帮助您节省物流费用。",
        },
      ],
      info: {
        title: "物流配送流程",
        desc: "",
        list: [
          { id: 1, title: "发起叫车" },
          { id: 2, title: "支付派车" },
          { id: 3, title: "打印送货单" },
          { id: 4, title: "装车配送" },
          { id: 5, title: "拍照上传" },
          { id: 6, title: "入场拍照" },
        ],
      },
    };
  },
};
</script>
<style lang="scss" scoped>
.md-Logistics {
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
}

.log-layout-1 {
  // margin: 0.4rem 0;
}

.log-layout-2 {
  padding: 0.4rem 0;

  .texts {
    background-color: #fff;
    padding-top: 0.2rem;
    padding-bottom: 0.18rem;
  }

  .ti {
    padding: 0.18rem 0.3rem;

    .d2 {
      line-height: calc(30 / 16);
      color: #444;
      margin-top: 0.22rem;
    }
  }
}

.log-layout-3 {
  .list {
    margin-top: 0.16rem;
  }

  .li {
    display: flex;
    padding: 0.3rem;
    background-color: #fff;
    border-radius: 4px;
    margin-bottom: 0.2rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .imgs {
    width: 4.4rem;
  }

  .img {
    position: relative;
    padding-top: calc(247 / 440 * 100%);
    overflow: hidden;

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: all 0.3s;
    }
  }

  .texts {
    width: 10.3rem;
    margin-left: 0.5rem;
    padding-top: 0.4rem;
    padding-bottom: 0.2rem;
  }

  .d2 {
    line-height: calc(30 / 16);
    color: #666;
    margin-top: 0.2rem;
  }
}
</style>
<style lang="scss">
.log-layout-1 {
  .banner {
    text-align: right;

    .text {
      left: auto;
      right: 1.33rem;
      top: 45%;
    }

    .tit {
      font-size: 0.5rem;
      text-shadow: 2px 2px 2px rgba($color: #000, $alpha: 0.2);
    }
  }
}
</style>